<template>
  <div :style="`minWidth: ${minWidth}`">
    <!-- All props passed, but not defined in the props section are available as $attrs -->
    <v-autocomplete
      v-if="!readonly"
      v-bind="$attrs"
      :label="label"
      :model-value="modelValue"
      :name="name"
      :chips="chips"
      :class="prependIcon ? '' : noIcon ? '' : 'pl-8'"
      :multiple="multiple"
      :rules="allRules"
      :clearable="clearable"
      @update:model-value="$emit('update:modelValue', $event)"
      @update:focused="focused = $event"
    >
      <template #prepend v-if="prependIcon">
        <mosaic-icon :color="focused ? 'primary' : undefined" :icon="prependIcon" />
      </template>
      <template v-if="$slots.item" #item="{ item }">
        <slot name="item" :item="item" />
      </template>
    </v-autocomplete>
    <!-- mb-4 will need to change if hide-details etc. are supplied -->
    <div v-else class="d-flex align-center mb-4" :data-test-name="name">
      <mosaic-icon v-if="prependIcon" class="mt-5" :icon="prependIcon" />
      <div v-else class="pl-6"></div>
      <div class="pl-4">
        <div class="text-caption">{{ label }}</div>
        <div v-if="!multiple" class="text-body-1">{{ modelValue || 'Not recorded' }}</div>
        <div v-else class="text-body-1">
          {{ (modelValue || []).length > 0 ? modelValue.join(', ') : 'Not recorded' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { requiredListInput } from '../../../utils/validations';
export default {
  name: 'MosaicAutocomplete',
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      required: false,
    },
    chips: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    modelValue: {
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: String,
      default: 'unset',
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  data: () => {
    return {
      requiredListInput,
      focused: false,
    };
  },
  computed: {
    allRules() {
      return this.required ? [this.requiredListInput(this.multiple), ...this.rules] : this.rules;
    },
  },
};
</script>
