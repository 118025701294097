<template>
  <v-list-item
    ripple
    class="mosaic-list-item"
    :class="{ 'px-1': smallScreen, 'mosaic-list-item-mobile': smallScreen }"
    :to="safeTo"
    :active="false"
    @click="$emit('click', $event)"
  >
    <template #prepend v-if="!smallScreen && icon">
      <v-avatar>
        <mosaic-icon :icon="icon" :size="iconSize" :color="iconColor" />
      </v-avatar>
    </template>

    <template #title>
      <v-list-item-title class="text-wrap">{{ title }}</v-list-item-title>
      <mosaic-error-snackbar
        v-if="errorSnackbar"
        :model-value="errorSnackbar.active"
        :message="errorSnackbar.message"
        contained
      />
    </template>

    <template #subtitle v-if="showSubtitle">
      <span v-if="subtitle" :class="{ 'text-red': redSubtitle }" style="word-break: break-word">{{ subtitle }}</span>
      <slot v-else name="subtitle"></slot>
    </template>

    <div class="d-flex align-center pt-2" v-if="smallScreen && showInformation">
      <slot v-if="smallScreen" name="information"></slot>
    </div>

    <template #append>
      <div class="d-flex align-center">
        <div
          v-if="showInformation && !smallScreen"
          class="d-flex align-center ml-2"
          :class="{ 'mr-4': hasActionsSlot }"
        >
          <v-chip v-if="chip" :color="chip.color">{{ chip.text }}</v-chip>
          <slot v-else name="information"></slot>
        </div>

        <template v-if="actions && actions.length">
          <template v-if="smallScreen">
            <mosaic-icon-btn
              v-if="primaryAction"
              :icon="primaryAction.icon"
              :color="primaryAction.iconColor"
              :tooltip="primaryAction.tooltip"
              :disabled="primaryAction.disabled"
              :disabled-tooltip="primaryAction.disabledTooltip"
              @click="primaryAction.click()"
            />
            <mosaic-icon-btn
              v-if="actionMenuItems.length === 1"
              :icon="actionMenuItems[0].icon"
              :icon-color="actionMenuItems[0].iconColor"
              :tooltip="actionMenuItems[0].tooltip"
              :disabled="actionMenuItems[0].disabled"
              :disabled-tooltip="actionMenuItems[0].disabledTooltip"
              @click="actionMenuItems[0].click()"
            />
            <mosaic-icon-btn-with-menu v-else-if="actionMenuItems.length > 1" :menu-items="actionMenuItems" />
          </template>
          <template v-else>
            <template v-for="action in actions" :key="action.icon">
              <mosaic-icon-btn
                v-if="!action.hide"
                :icon="action.icon"
                :color="action.iconColor"
                :tooltip="action.tooltip"
                :disabled="action.disabled"
                :disabled-tooltip="action.disabledTooltip"
                @click="action.click()"
              />
            </template>
          </template>
        </template>
        <slot name="actions"></slot>

        <v-list-item-action v-if="!smallScreen && (to || showChevron)" :end="true">
          <v-icon>mdi-chevron-right</v-icon>
        </v-list-item-action>
      </div>
    </template>
  </v-list-item>
</template>

<script setup lang="ts">
import { computed, useSlots } from 'vue';
import { useRoute, type RouteLocationNamedRaw } from 'vue-router';

const props = defineProps<{
  icon?: string;
  iconColor?: string;
  iconSize?: 'small' | 'large' | 'x-large';
  title: string;
  subtitle?: string;
  redSubtitle?: boolean;
  to?: RouteLocationNamedRaw;
  showChevron?: boolean;
  chip?: { color: string; text: string };
  actions?: {
    icon: string;
    tooltip?: string;
    iconColor?: string;
    disabled?: boolean;
    disabledTooltip?: string;
    hide?: boolean;
    click: () => void;
  }[];
  errorSnackbar?: {
    active: boolean;
    message: string;
  };
}>();

const mappedAndFilteredActions = computed(() => props.actions?.filter(x => !x.hide) || []);

const primaryAction = computed(() => mappedAndFilteredActions.value?.[0]);
const actionMenuItems = computed(() => mappedAndFilteredActions.value?.slice(1));

defineEmits<{
  click: [e: MouseEvent | KeyboardEvent];
}>();

const slots = useSlots();
const showSubtitle = computed(() => !!slots.subtitle || !!props.subtitle);
const hasActionsSlot = computed(() => !!slots.actions);
const showInformation = computed(() => !!slots.information || !!props.chip);

// A fix for a "Missing required param" error - details https://github.com/vuetifyjs/vuetify/issues/17176#issuecomment-1517843304
// If that issue is fixed then this can probably be removed
const originalRouteParams = { ...useRoute().params };
const safeTo = computed(() =>
  props.to ? { ...props.to, params: { ...originalRouteParams, ...props.to.params } } : undefined
);
</script>

<style>
.v-application--is-ltr .mosaic-list-item-mobile .v-list-item__action:last-of-type:not(:only-child) {
  margin-left: 0;
}
</style>
