import type {
  Cohort,
  Institution,
  Permission,
  Student,
  SelectedStudent,
  StudentCohort,
  StudentUser,
} from '@/store/map-store';
import type { StudentSidebarConfig } from './student-sidebar';
import { studentSidebarConfig } from './student-sidebar';
import type { SidebarItemConfig } from './common';
import { hasSubItems } from './common';
import type { CohortSidebarConfig } from './cohort-sidebar';
import { cohortSidebarConfig } from './cohort-sidebar';
import type { InstitutionSidebarConfig } from './institution-sidebar';
import { institutionSidebarConfig } from './institution-sidebar';
import type { SchoolSidebarConfig } from './school-sidebar';
import { schoolSidebarConfig } from './school-sidebar';
import type { CohortResponse } from '@/types/responses';

export function createStudentSidebar(
  reviewNounCapitalisedAndPluralised: string,
  user: StudentUser,
  institution: Institution,
  curriculumVisible: boolean
): SidebarItem[] {
  return createStudentLevelSidebar(
    reviewNounCapitalisedAndPluralised,
    c => Boolean(c.hideForStudents && c.hideForStudents(institution)),
    c => createStudentSidebarItem(c, user),
    user.student.cohort,
    institution,
    curriculumVisible,
    user.student
  );
}

function createStudentSidebarItem(x: StudentSidebarConfig, user: StudentUser) {
  return {
    icon: x.icon,
    title: x.title,
    pageName: 'Student' + x.pageNameSuffix,
    badge: x.studentBadge ? x.studentBadge(user) : null,
    children: (
      x.childPageNameSuffixes
        ?.map(s => 'Student' + s)
        .concat(x.legacyChildPageNameSuffixes?.map(s => 'LegacyStudent' + s) || []) || []
    ).concat([x.pageNameSuffix, ...(x.childPageNameSuffixes || [])]), // This is a hack until all the Student and Tutor pages have been merged
    subItems: [],
  };
}

export function createStaffStudentSidebar(
  reviewNounCapitalisedAndPluralised: string,
  student: SelectedStudent,
  cohort: Cohort,
  institution: Institution,
  curriculumVisible: boolean,
  userStaffHasPermissionForSelectedStudent: (permission: Permission) => boolean
): SidebarItem[] {
  return createStudentLevelSidebar(
    reviewNounCapitalisedAndPluralised,
    c => {
      return (
        Boolean(c.hideForStaff) ||
        Boolean(c.staffAnyPermissions && !c.staffAnyPermissions.some(p => userStaffHasPermissionForSelectedStudent(p)))
      );
    },
    c => createStaffStudentSidebarItem(c),
    cohort,
    institution,
    curriculumVisible,
    student
  );
}

function createStaffStudentSidebarItem(x: StudentSidebarConfig) {
  return {
    icon: x.icon,
    title: x.title,
    pageName: x.tutorPageNameOverride || 'Tutor' + x.pageNameSuffix,
    badge: null,
    children: (
      x.childPageNameSuffixes
        ?.map(s => 'Tutor' + s)
        .concat(x.legacyChildPageNameSuffixes?.map(s => 'LegacyTutor' + s) || []) || []
    ).concat([x.pageNameSuffix, ...(x.childPageNameSuffixes || [])]), // This is a hack until all the Student and Tutor pages have been merged,
    subItems: [],
  };
}

export function createCohortSidebar(
  traineeNounCapitalisedAndPluralised: string,
  reviewNounCapitalisedAndPluralised: string,
  cohort: CohortResponse,
  institution: Institution,
  userStaffHasPermissionForSelectedCohort: (permission: Permission) => boolean
): SidebarItem[] {
  const config = cohortSidebarConfig(traineeNounCapitalisedAndPluralised, reviewNounCapitalisedAndPluralised);
  const show = (config: CohortSidebarConfig) => {
    return (
      (!config.show || config.show(cohort, institution)) &&
      config.anyPermissions.some(p => userStaffHasPermissionForSelectedCohort(p))
    );
  };
  return createSidebar(config, show, createCohortSidebarItem);
}

function createCohortSidebarItem(x: CohortSidebarConfig) {
  return {
    icon: x.icon,
    title: x.title,
    pageName: x.pageName,
    badge: null,
    children: x.children || [],
    subItems: [],
  };
}

export function createInsitutionSidebar(
  traineeNounCapitalised: () => string,
  traineeNounCapitalisedAndPluralised: string,
  reviewNounCapitalisedAndPluralised: string,
  institution: Institution,
  userStaffHasPermission: (permission: Permission) => boolean
): SidebarItem[] {
  const config = institutionSidebarConfig(
    traineeNounCapitalised,
    traineeNounCapitalisedAndPluralised,
    reviewNounCapitalisedAndPluralised
  );
  const show = (config: InstitutionSidebarConfig) => {
    return (!config.show || config.show(institution)) && config.allPermissions.every(p => userStaffHasPermission(p));
  };
  return createSidebar(config, show, createInstitutionSidebarItem);
}

function createInstitutionSidebarItem(x: InstitutionSidebarConfig) {
  return {
    icon: x.icon,
    title: x.title,
    pageName: x.pageName,
    badge: null,
    children: x.children || [],
    subItems: [],
  };
}

export function createSchoolSidebar(traineeNounCapitalisedAndPluralised: string): SidebarItem[] {
  const config = schoolSidebarConfig(traineeNounCapitalisedAndPluralised);
  const show = () => true;
  return createSidebar(config, show, createSchoolSidebarItem);
}

function createSchoolSidebarItem(x: SchoolSidebarConfig) {
  return {
    icon: x.icon,
    title: x.title,
    pageName: x.pageName,
    badge: null,
    children: x.children || [],
    subItems: [],
  };
}

function createStudentLevelSidebar(
  reviewPointsTitle: string,
  hide: (configItem: StudentSidebarConfig) => boolean,
  createSidebarItem: (configItem: StudentSidebarConfig) => SidebarItem,
  cohort: Cohort | StudentCohort,
  institution: Institution,
  curriculumVisible: boolean,
  student: Student | SelectedStudent
): SidebarItem[] {
  const config = studentSidebarConfig(reviewPointsTitle);
  const show = (config: StudentSidebarConfig) => {
    return (!config.show || config.show(cohort, institution, curriculumVisible, student)) && !hide(config);
  };
  return createSidebar(config, show, createSidebarItem);
}

function createSidebar<T extends object>(
  config: SidebarItemConfig<T>[],
  show: (config: T) => boolean,
  createSidebarItem: (config: T) => SidebarItem
): SidebarItem[] {
  const topLevelItems = config.filter(x => (hasSubItems(x) ? true : show(x)));

  const visibleTopLevelItems: SidebarItem[] = [];
  for (const item of topLevelItems) {
    if (hasSubItems(item)) {
      const subItems = item.children.filter(show).map(c => createSidebarItem(c));
      if (subItems.length === 0) continue;

      visibleTopLevelItems.push({
        icon: item.icon,
        title: item.title,
        pageName: subItems[0].pageName,
        badge: null,
        children: subItems.flatMap(s => [s.pageName].concat(s.children)),
        subItems,
      });
    } else {
      visibleTopLevelItems.push(createSidebarItem(item));
    }
  }
  return visibleTopLevelItems;
}

export interface SidebarItem {
  icon: string;
  title: string;
  pageName: string;
  badge: number | null;
  children: string[];
  subItems: SidebarItem[];
}
