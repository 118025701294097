<template>
  <div class="overlay"></div>
  <div ref="card" class="draggable-card" @mousedown="startDrag" @touchstart="startDrag">
    <div class="flex-row">
      <div class="flex-row">
        <mosaic-icon class="mr-2" icon="mdi-drag" />
        <mosaic-card-title v-if="title"> {{ title }}</mosaic-card-title>
      </div>
      <mosaic-close-icon-btn @click="closeCard" />
    </div>
    <div>
      <slot> </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from 'vue';

const props = defineProps<{
  x: number;
  y: number;
  title: string | null;
  closeCard: () => void;
}>();

//distance from the clicked element
const marginY = 55;
const marginX = 150;
const card = ref<HTMLElement | null>(null);
const position = ref({ top: marginY, left: marginX });
const isDragging = ref(false);
const offset = ref({ x: 0, y: 0 });

const startDrag = (event: MouseEvent | TouchEvent) => {
  if (window.innerWidth <= 992) return;
  isDragging.value = true;

  //coordinates in the viewport
  const clientX = (event as MouseEvent).clientX || (event as TouchEvent).touches[0].clientX;
  const clientY = (event as MouseEvent).clientY || (event as TouchEvent).touches[0].clientY;

  //calculate the initial offset between the mouse/touch point and the top-left corner
  //of the draggable card
  offset.value.x = clientX - position.value.left;
  offset.value.y = clientY - position.value.top;

  document.addEventListener('mousemove', onDrag);
  document.addEventListener('mouseup', stopDrag);
  document.addEventListener('touchmove', onDrag);
  document.addEventListener('touchend', stopDrag);
};

const onDrag = (event: MouseEvent | TouchEvent) => {
  if (!isDragging.value || window.innerWidth <= 992) return;

  const clientX = (event as MouseEvent).clientX || (event as TouchEvent).touches[0].clientX;
  const clientY = (event as MouseEvent).clientY || (event as TouchEvent).touches[0].clientY;

  // Ensure the card does not go out of window bounds
  const cardWidth = card.value?.offsetWidth || 0;
  const cardHeight = card.value?.offsetHeight || 0;
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  position.value.left = Math.max(0, Math.min(clientX - offset.value.x, windowWidth - cardWidth));
  position.value.top = Math.max(0, Math.min(clientY - offset.value.y, windowHeight - cardHeight));

  updateCardPosition();
};

const stopDrag = () => {
  if (window.innerWidth <= 992) return;
  isDragging.value = false;

  document.removeEventListener('mousemove', onDrag);
  document.removeEventListener('mouseup', stopDrag);
  document.removeEventListener('touchmove', onDrag);
  document.removeEventListener('touchend', stopDrag);
};

const updateCardPosition = () => {
  if (card.value && window.innerWidth >= 992) {
    card.value.style.left = `${position.value.left}px`;
    card.value.style.top = `${position.value.top}px`;
  }
};

const onResize = () => {
  if (card.value) {
    //if after resizing from a smaller to a bigger screen -> last known position
    const hasSpaceToRight = window.innerWidth > (card.value?.offsetWidth || 0) + position.value.left + marginX;
    const left = hasSpaceToRight ? position.value.left : window.innerWidth - marginX - (card.value?.offsetWidth || 0);

    card.value.style.left = `${left}px`;
    card.value.style.top = `${position.value.top}px`;
  }
};

onMounted(() => {
  if (card.value && window.innerWidth >= 992) {
    const hasSpaceToRight = window.innerWidth > card.value?.offsetWidth + props.x + marginX;
    const left = hasSpaceToRight ? props?.x + marginX : window.innerWidth - marginX - card.value?.offsetWidth;
    position.value.left = left;
  }
  updateCardPosition();
  window.addEventListener('resize', onResize);
});

onUnmounted(() => {
  document.removeEventListener('mousemove', onDrag);
  document.removeEventListener('mouseup', stopDrag);
  document.removeEventListener('touchmove', onDrag);
  document.removeEventListener('touchend', stopDrag);
  window.removeEventListener('resize', onResize);
});
</script>

<style scoped>
/* overlay - avoiding click events outside (only for small devices) */
.overlay {
  position: fixed;
  top: 64px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: all;
  display: none;
}
.draggable-card {
  z-index: 2;
  padding: 10px;
  position: fixed;
  cursor: grab;
  user-select: none;
  touch-action: none;
  box-sizing: border-box;
  width: 800px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  background-color: white;
  color: black;
  padding: 20px;
  overflow: auto;
}

.flex-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 992px) {
  .draggable-card {
    max-width: 90vw;
    max-height: 90vh;
    width: 90vw !important;
    min-width: initial;
    position: fixed;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
  }
  .draggable {
    display: none;
  }
  .overlay {
    display: block;
  }
}
</style>
