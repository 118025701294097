import type { Institution, Permission } from '@/store/map-store';
import type { SidebarItemConfig } from './common';
import { icons } from '@/utils/icons';
import type { CohortResponse } from '@/types/responses';

export function cohortSidebarConfig(
  traineeNounCapitalisedAndPluralised: string,
  reviewNounCapitalisedAndPluralised: string
): SidebarItemConfig<CohortSidebarConfig>[] {
  return [
    {
      icon: icons.students,
      title: traineeNounCapitalisedAndPluralised,
      pageName: 'TutorStudentListPage',
      anyPermissions: ['Admin'],
    },
    {
      icon: icons.instructor,
      title: 'Instructors',
      children: [
        {
          icon: icons.instructorTraining,
          title: 'Instructor Training',
          pageName: 'TutorCohortStaffTrainingPage',
          anyPermissions: ['staff.training.edit'],
          show: cohort => cohort.has_staff_training,
          children: ['TutorCohortStaffTrainingPage', 'TutorCohortStaffTrainingModulePage'],
        },
      ],
    },
    {
      icon: icons.course,
      title: 'Course',
      children: [
        {
          icon: icons.curriculum,
          title: 'Curriculum',
          pageName: 'TutorAdminCohortCurriculumPage',
          anyPermissions: ['Admin'],
          show: (cohort, institution) =>
            (cohort.has_curriculum || institution.config.has_curriculum) && !institution.config.early_careers,
          children: [
            'TutorAdminCohortCurriculumThemePage',
            'CohortAdminCurriculumStatementPage',
            'CohortAdminCurriculumTeachingStrategyPage',
          ],
        },
        {
          icon: icons.trainingPlan,
          title: 'Training Plan',
          pageName: 'TutorAdminCohortCoursePage',
          anyPermissions: ['cohortCourses.edit'],
          show: (_, institution) => !institution.config.early_careers,
          children: [
            'TutorAdminCohortCourseTermPage',
            'TutorAdminCohortCourseWeekPage',
            'TutorAdminCohortCourseWeekTemplatePage',
            'TutorAdminCohortCourseWeekSectionPage',
            'TutorAdminCohortCourseWeekTemplateSectionPage',
          ],
        },
        {
          icon: icons.mentorMeeting,
          title: 'Mentor Meetings',
          pageName: 'CohortMentorMeetingsPage',
          anyPermissions: ['mentorMeetingTemplates.edit', 'Admin'],
          show: (_, institution) => institution.config.show_mentor_meetings && !institution.config.early_careers,
        },
        {
          icon: icons.courseActivity,
          title: 'Activities',
          pageName: 'CohortAssignmentsListPage',
          anyPermissions: ['assignments.edit'],
          show: (_, institution) => !institution.config.early_careers,
          children: ['CohortAssignmentCreatePage', 'CohortAssignmentEditPage', 'CohortAssignmentViewPage'],
        },
        {
          icon: icons.target,
          title: 'Targets',
          pageName: 'CohortCourseTargetsPage',
          anyPermissions: ['Admin'],
          show: (_, institution) => !institution.config.early_careers,
          children: ['CohortCourseTargetCreatePage', 'CohortCourseTargetEditPage'],
        },
        {
          icon: icons.reflection,
          title: 'Reflections',
          pageName: 'CohortCourseReflectionsPage',
          anyPermissions: ['Admin'],
          show: (_, institution) => !institution.config.early_careers,
          children: ['CohortCourseReflectionCreatePage', 'CohortCourseReflectionEditPage'],
        },
      ],
    },
    {
      icon: icons.development,
      title: 'Development',
      children: [
        {
          icon: icons.target,
          title: 'Targets',
          pageName: 'CohortDevelopmentTargetsPage',
          anyPermissions: ['Admin'],
          show: (_, institution) => !institution.config.early_careers,
        },
        {
          icon: icons.reflection,
          title: 'Reflections',
          pageName: 'CohortDevelopmentReflectionsPage',
          anyPermissions: ['Admin'],
          show: (_, institution) => !institution.config.early_careers,
        },
        {
          icon: icons.lessonObservation,
          title: 'Lesson Observations',
          pageName: 'CohortDevelopmentLessonObservationsPage',
          anyPermissions: ['Admin'],
          show: (_, institution) => institution.config.show_lesson_observations && !institution.config.early_careers,
        },
      ],
    },
    {
      icon: icons.announcement,
      title: 'Announcements',
      children: [
        {
          icon: icons.student,
          title: traineeNounCapitalisedAndPluralised,
          pageName: 'CohortAdminAnnouncementsPage',
          anyPermissions: ['announcements.edit'],
          show: (_, institution) => !institution.config.early_careers,
          children: ['CohortAdminAnnouncementCreatePage', 'CohortAdminAnnouncementEditPage'],
        },
        {
          icon: icons.instructor,
          title: 'Instructors',
          pageName: 'CohortAdminStaffAnnouncementsPage',
          anyPermissions: ['staff.announcements.edit'],
          show: (_, institution) => !institution.config.early_careers,
          children: ['CohortAdminStaffAnnouncementCreatePage', 'CohortAdminStaffAnnouncementEditPage'],
        },
      ],
    },
    {
      icon: icons.reviewPoint,
      title: reviewNounCapitalisedAndPluralised,
      pageName: 'CohortReviewsPage',
      anyPermissions: ['Admin'],
      show: (_, institution) => institution.config.show_reviews,
      children: [
        'TutorAdminReviewCreatePage',
        'TutorAdminReviewEditPage',
        'LegacyTutorAdminReviewPage',
        'TutorAdminReviewViewPage',
      ],
    },
    {
      icon: icons.group,
      title: 'Groups',
      pageName: 'TutorGroupsListPage',
      anyPermissions: ['groups.edit'],
      show: (_, institution) => !institution.config.early_careers,
      children: ['TutorGroupPage'],
    },
    {
      icon: icons.settings,
      title: 'Settings',
      pageName: 'TutorAdminCohortEditPage',
      anyPermissions: ['Admin'],
      show: (_, institution) => !institution.config.early_careers,
    },
  ];
}

export interface CohortSidebarConfig {
  icon: string;
  title: string;
  pageName: string;
  anyPermissions: Permission[];
  show?: (cohort: CohortResponse, institution: Institution) => boolean;
  children?: string[];
}
