<template>
  <div>
    <app-toolbar
      v-model:drawer="drawer"
      :do-not-show-navigation="doNotShowNavigation"
      :margin-top="appMessageHeight || '0'"
    />
    <v-navigation-drawer
      v-if="!doNotShowNavigation"
      v-model="drawer"
      disable-route-watcher
      :class="navigationDrawerClass"
      mobile-breakpoint="md"
    >
      <div class="d-flex flex-column h-100" data-test-sidebar>
        <div style="overflow-y: auto">
          <v-list class="pb-0">
            <template v-if="user && user.isAdmin">
              <menu-item
                icon="book-open"
                page-name="AdminPage"
                title="Institutions"
                :children="[
                  'AdminInstitutionPage',
                  'AdminInstitutionCurriculumPage',
                  'AdminInstitutionCurriculumThemePage',
                  'AdminInstitutionStandardSetPage',
                  'AdminInstitutionConfigPage',
                  'AdminInstitutionCreditsPage',
                  'AdminInstitutionCohortPage',
                  'AdminInstitutionStaffPage',
                ]"
              />
              <menu-item
                icon="account-multiple"
                page-name="AdminUsersPage"
                title="Users"
                :children="['AdminUserPage']"
              />
              <menu-item icon="key" page-name="AdminPermissionsPage" title="Permissions" />
              <menu-item icon="light-switch" page-name="AdminFeatureSwitchesPage" title="Feature Switches" />
              <menu-item
                icon="hexagon-multiple"
                page-name="AdminCurriculumTemplatesPage"
                title="Curriculum Templates"
                :children="['AdminCurriculumThemeTemplatesPage', 'AdminCurriculumStatementTemplatesPage']"
              />
              <menu-item
                icon="list-status"
                page-name="AdminStandardSetsPage"
                title="Standard Sets"
                :children="['AdminStandardSetStandardsPage', 'AdminStandardSetSubstandardsPage']"
              />
              <menu-item icon="bookshelf" page-name="AdminSubjectsPage" title="Subjects" />
              <menu-item
                icon="book-education-outline"
                page-name="AdminProfessionalResourcesPage"
                title="Professional Resources"
              />
              <menu-item icon="pencil-box-multiple" page-name="AdminJudgementSetsPage" title="Judgement Sets" />
              <menu-item icon="file-certificate" page-name="AdminCertificateTypesPage" title="Certificate Types" />
            </template>
            <template v-if="user && user.staff">
              <menu-item v-if="$route.name === 'UserHomePage'" icon="home" page-name="UserHomePage" title="Home" />
              <template v-if="userStaff">
                <menu-item
                  icon="view-dashboard"
                  page-name="TutorHomePage"
                  title="Dashboard"
                  :subtitle="selectedInstitution.name"
                  :routing-params="{ staffId: userStaff.id }"
                  :children="[
                    'TutorStaffFilesListPage',
                    'TutorStaffTrainingPage',
                    'MyStaffTrainingModulePage',
                    'MyStaffTrainingCompetencyThemePage',
                    'MyStaffTrainingRecordCreatePage',
                    'MyStaffTrainingRecordEditPage',
                    'MyStaffTrainingCertificateCreatePage',
                    'MyStaffTrainingCertificateEditPage',
                    'StaffAnnouncementsPage',
                    'StaffAnnouncementPage',
                  ]"
                />
                <template v-if="!selectedCohort && !selectedSchoolPages">
                  <menu-items
                    :title="selectedInstitution.name"
                    :items="institutionMenuItems"
                    :routing-params="{ institutionId: selectedInstitution.id }"
                  />
                </template>
                <template v-if="selectedCohort && !selectedStudent">
                  <menu-items
                    :title="selectedCohort.name"
                    :items="cohortMenuItems"
                    :routing-params="{ cohortId: selectedCohort.id }"
                    :show-back="userStaffHasPermission('Admin')"
                    :back="{
                      title: selectedInstitution.name,
                      to: { name: 'TutorCohortListPage', params: { institutionId: selectedInstitution.id } },
                    }"
                  />
                </template>
                <template v-if="selectedSchoolPages && !selectedStudent">
                  <menu-items
                    :title="selectedSchool.name"
                    :items="schoolMenuItems"
                    :routing-params="{ schoolId: selectedSchool.id }"
                    :show-back="userStaffHasPermission('Admin')"
                    :back="{
                      title: selectedInstitution.name,
                      to: { name: 'TutorSchoolsListPage', params: { institutionId: selectedInstitution.id } },
                    }"
                  />
                </template>
                <template v-if="selectedStudent">
                  <menu-items
                    :title="selectedStudent.name"
                    :items="staffStudentMenuItems"
                    :routing-params="{ studentId: selectedStudent.id }"
                    :show-back="
                      !selectedInstitution.config.early_careers
                        ? userStaffHasPermissionForSelectedCohort('Admin')
                        : !!selectedSchool || !!findInstitutionLevelHomePage(userStaff)
                    "
                    :back="
                      !selectedInstitution.config.early_careers
                        ? {
                            title: selectedCohort.name,
                            to: { name: 'TutorStudentListPage', params: { cohortId: selectedCohort.id } },
                          }
                        : selectedSchool
                        ? {
                            title: selectedSchool.name,
                            to: {
                              name: 'TutorSchoolStudentsPage',
                              params: { schoolId: selectedSchool.id },
                            },
                          }
                        : {
                            title: selectedInstitution.name,
                            to: findInstitutionLevelHomePage(userStaff),
                          }
                    "
                  >
                    <template v-if="studentsWithName.length > 1" #title>
                      <v-autocomplete
                        v-model="autocompleteSelectedStudentId"
                        variant="outlined"
                        class="pr-2 mt-4 pl-4 mb-1"
                        :label="traineeNounCapitalised()"
                        :items="studentsWithName"
                        item-title="name"
                        item-value="id"
                        hide-details
                      />
                    </template>
                  </menu-items>
                </template>
              </template>
            </template>
            <template v-if="user && user.student">
              <template v-for="item in studentMenuItems" :key="item.title">
                <menu-item
                  v-if="item.subItems.length === 0"
                  :icon="item.icon"
                  :title="item.title"
                  :page-name="item.pageName"
                  :routing-params="{ studentId: userStudent.id }"
                  :is-side-bar-list-parent="item.subItems.length > 0"
                  :children="item.children"
                />
                <div v-else class="pt-2 pl-3 text-subtitle-1 font-weight-bold">{{ item.title }}</div>
                <menu-item
                  v-for="subItem in item.subItems"
                  :key="subItem.title"
                  :icon="subItem.icon"
                  :title="subItem.title"
                  :page-name="subItem.pageName"
                  :routing-params="{ studentId: userStudent.id }"
                  :children="subItem.children"
                  :badge="subItem.badge"
                />
              </template>
            </template>
          </v-list>
        </div>
        <div class="flex-grow-1" />
        <v-card v-if="user && !user.isAdmin" class="flex-shrink-0">
          <v-card-text raised>
            <div class="font-weight-bold">Need help or have feedback?</div>
            <template v-if="!selectedInstitution || selectedInstitution.config.early_careers">
              <div>
                <span class="text-primary" style="cursor: pointer" @click.prevent="contactSupport()"
                  >Contact Support</span
                >
              </div>
            </template>
            <template v-else>
              <div>
                Try our
                <a
                  :href="`http://help.mosaic.penrose.education/books/${
                    user.student ? 'how-to-guide-for-trainees' : 'how-to-guide-for-instructors'
                  }`"
                  target="_blank"
                  >help site</a
                >
                or
                <span
                  class="text-primary text-decoration-underline"
                  style="cursor: pointer"
                  @click.prevent="contactSupport()"
                  >Contact Support</span
                >
              </div></template
            ></v-card-text
          >
        </v-card>
      </div>
    </v-navigation-drawer>

    <v-main :style="{ 'margin-top': appMessageHeight || '0' }">
      <v-container fluid>
        <v-container
          :class="{
            'px-0': smallScreen,
            'pt-0': smallScreen,
          }"
        >
          <v-row v-if="doNotShowNavigation" align="center" justify="center">
            <v-col v-if="!fullWidth" cols="12" sm="8" md="6" lg="6" xl="6" class="pt-6">
              <slot />
            </v-col>
            <v-col v-else>
              <slot />
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col :class="{ 'pt-0': breadcrumbs && breadcrumbs.length > 0 }">
              <mosaic-breadcrumbs :breadcrumbs="breadcrumbs" />
              <slot />
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-main>
    <template v-if="user && !user.isAdmin">
      <mosaic-support-dialog v-model:active="supportDialog.active" />
      <ndt-dialog v-model:active="breakingUpdateDialog.active" title="Mosaic Update Required" width="95vw">
        <div style="height: 60vh" class="d-flex flex-column justify-center align-center px-10">
          <div class="text-h3 mb-8">Mosaic Update Required</div>
          <div class="text-h5 mb-4">
            At Mosaic we've been making some changes to how our platform works. For Mosaic to continue working properly
            in your browser, you'll need to update to the latest version. You can do this by clicking "Update Mosaic"
            below.
          </div>
          <div class="text-h5 mb-4">
            If you have any unsaved work, please press cancel and save your work (or copy it to a document). You can
            then update Mosaic by pressing "Update Mosaic" in the top right of the page.
          </div>
          <div class="text-h5 mb-4">Many thanks for your understanding,</div>
          <div class="text-h5">The Mosaic Team</div>
        </div>
        <template #buttons>
          <v-btn variant="text" ripple color="success" @click.prevent="refreshPage()">Update Mosaic</v-btn>
        </template>
      </ndt-dialog>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import MenuItem from './MenuItem.vue';
import MenuItems from './MenuItems.vue';
import AppToolbar from './AppToolbar.vue';
import NdtDialog from '@/components/NdtDialog.vue';
import { evidenceTypes } from '@/models/evidence-types';
import featureSwitches from '@/utils/feature-switches';
import config from '@/utils/config';
import MosaicSupportDialog from '@/components/library/dialogs/MosaicSupportDialog.vue';
import { isNavigationFailure, NavigationFailureType } from 'vue-router';
import MosaicBreadcrumbs from '@/components/MosaicBreadcrumbs.vue';
import {
  createStudentSidebar,
  createStaffStudentSidebar,
  createCohortSidebar,
  createInsitutionSidebar,
  createSchoolSidebar,
} from '@/utils/sidebar/sidebar';
import { findInstitutionLevelHomePage } from '@/router/guard-methods';
import $ from 'jquery';
import { useCohortStore } from '@/stores/cohort';

export default {
  props: {
    visibleAppMessage: {
      type: Object,
      default: () => null,
    },
  },
  components: { MenuItem, MenuItems, AppToolbar, NdtDialog, MosaicBreadcrumbs, MosaicSupportDialog },
  provide() {
    return {
      layoutContainerContactSupport: () => this.contactSupport(),
    };
  },
  setup() {
    const { selectedCohortStudents } = useCohortStore();
    return { selectedCohortStudents };
  },
  data() {
    return {
      findInstitutionLevelHomePage,
      types: evidenceTypes,
      checked: evidenceTypes,
      featureSwitches: featureSwitches,
      config: config,
      supportDialog: {
        active: false,
      },
      breakingUpdateDialog: {
        active: false,
      },
      autocompleteSelectedStudentId: null,
      doNotShowNavigation: false,
      fullWidth: false,
      appMessageHeight: '',
    };
  },
  computed: {
    ...mapState([
      'user',
      'selectedStudent',
      'selectedSchool',
      'selectedCohort',
      'selectedInstitution',
      'userStaff',
      'userStudent',
      'cohorts',
      'breadcrumbs',
      'isNavigating',
    ]),
    ...mapGetters([
      'curriculumEnabled',
      'reviewNounCapitalised',
      'reviewNounCapitalisedAndPluralised',
      'reviewNounPluralised',
      'appUpdateAvailable',
      'breakingAppUpdateRequired',
      'curriculumVisible',
      'userStaffStudents',
    ]),
    studentMenuItems() {
      return createStudentSidebar(
        this.reviewNounCapitalisedAndPluralised,
        this.user,
        this.selectedInstitution,
        this.curriculumVisible
      );
    },
    staffStudentMenuItems() {
      return createStaffStudentSidebar(
        this.reviewNounCapitalisedAndPluralised,
        this.selectedStudent,
        this.selectedCohort,
        this.selectedInstitution,
        this.curriculumVisible,
        this.userStaffHasPermissionForSelectedStudent
      );
    },
    cohortMenuItems() {
      return createCohortSidebar(
        this.traineeNounCapitalisedAndPluralised,
        this.reviewNounCapitalisedAndPluralised,
        this.selectedCohort,
        this.selectedInstitution,
        this.userStaffHasPermissionForSelectedCohort
      );
    },
    schoolMenuItems() {
      return createSchoolSidebar(this.traineeNounCapitalisedAndPluralised);
    },
    institutionMenuItems() {
      return createInsitutionSidebar(
        this.traineeNounCapitalised,
        this.traineeNounCapitalisedAndPluralised,
        this.reviewNounCapitalisedAndPluralised,
        this.selectedInstitution,
        this.userStaffHasPermission
      );
    },
    canAuthorInstructorCourseFiles() {
      return (
        this.userStaffHasPermission('staff.edit') &&
        this.userStaffHasPermission('courseFiles.edit') &&
        !this.selectedInstitution.config.early_careers
      );
    },
    studentsWithName() {
      return (this.selectedCohortStudents.length > 0 ? this.selectedCohortStudents : this.userStaffStudents)?.map(
        s => ({ ...s, name: s.name || s.email })
      );
    },
    drawer: {
      get() {
        return this.$store.state.navigationDrawer;
      },
      set(x) {
        this.$store.commit('updateNavigationDrawer', x);
      },
    },
    pleaseGetInTouchWithContacts() {
      if (this.selectedInstitution) {
        return `Then please get in touch with your contact at ${this.selectedInstitution.name}`;
      }
      const institutionNames = this.user.staff.map(st => st.institution.name);
      if (institutionNames.length === 1) {
        return `Then please get in touch with your contact at ${institutionNames[0]}`;
      }
      return `Then please get in touch with your contact at one of ${institutionNames
        .slice(0, institutionNames.length - 1)
        .join(', ')} or ${institutionNames[institutionNames.length - 1]}`;
    },
    selectedSchoolPages() {
      return this.selectedSchool && this.$route.name !== 'TutorEctApproveRegistrationPage';
    },
    navigationDrawerClass() {
      // I attempted dynamically setting the 'top' attribute of the v-navigation-drawer (via Vue and jQuery) and it didn't appear to be responsive
      // However, I can set it via CSS classes, so this is the compromise. It would be better if it was properly dynamic
      const height = this.appMessageHeight ? parseInt(this.appMessageHeight.split('px')[0]) : null;
      return {
        'one-line-app-message': height && height <= 40,
        'two-line-app-message': height && height > 40 && height <= 64,
        'three-line-app-message': height && height > 64 && height <= 88,
        'four-line-app-message': height && height > 88 && height <= 112,
        'five-line-app-message': height && height > 112,
      };
    },
  },
  watch: {
    $route: {
      handler(to) {
        this.doNotShowNavigation = !!to.meta?.noLayoutContainerNavigation;
        this.fullWidth = !!to.meta?.layoutContainerFullWidthWhenNoNavigation;
      },
      deep: true,
    },
    selectedStudent() {
      this.autocompleteSelectedStudentId = this.selectedStudent?.id;
    },
    async autocompleteSelectedStudentId(studentId) {
      if (!this.selectedStudent || this.selectedStudent.id === studentId) return;
      const result = await this.$router.push({ name: this.$route.name, params: { studentId } });
      if (isNavigationFailure(result, NavigationFailureType.aborted)) {
        this.autocompleteSelectedStudentId = this.selectedStudent.id;
      } else {
        this.$store.commit('clearStudentStaffRoles');
      }
    },
    breakingAppUpdateRequired(x) {
      if (x) {
        this.breakingUpdateDialog.active = true;
      } else {
        this.breakingAppUpdateRequired.active = false;
      }
    },
    visibleAppMessage() {
      this.setAppMessageHeight();
    },
  },
  created() {
    this.autocompleteSelectedStudentId = this.selectedStudent?.id;
    this.doNotShowNavigation = !!this.$route.meta?.noLayoutContainerNavigation;
    this.fullWidth = !!this.$route.meta?.layoutContainerFullWidthWhenNoNavigation;
  },
  mounted() {
    this.setAppMessageHeight();
    window.addEventListener('resize', this.setAppMessageHeight);
  },
  unmounted() {
    window.removeEventListener('resize', this.setAppMessageHeight);
  },
  methods: {
    isOn: function (pageName) {
      return this.$route.name === pageName;
    },
    contactSupport() {
      this.supportDialog.active = true;
      this.$store.dispatch('loadVersionsAndAppMessages');
    },
    setAppMessageHeight() {
      this.appMessageHeight = $('#app-message').css('height');
    },
  },
};
</script>

<style scoped>
.toolbar {
  z-index: 30;
}
</style>

<style>
.one-line-app-message {
  top: 104px !important;
}

.one-line-app-message > .v-navigation-drawer__content {
  height: calc(100% - 40px) !important;
}

.two-line-app-message {
  top: 128px !important;
}

.two-line-app-message > .v-navigation-drawer__content {
  height: calc(100% - 64px) !important;
}

.three-line-app-message {
  top: 152px !important;
}

.three-line-app-message > .v-navigation-drawer__content {
  height: calc(100% - 88px) !important;
}

.four-line-app-message {
  top: 176px !important;
}

.four-line-app-message > .v-navigation-drawer__content {
  height: calc(100% - 112px) !important;
}

.five-line-app-message {
  top: 200px !important;
}

.five-line-app-message > .v-navigation-drawer__content {
  height: calc(100% - 136px) !important;
}
</style>
