import type {
  Cohort,
  Institution,
  Permission,
  Student,
  SelectedStudent,
  StudentCohort,
  StudentUser,
} from '@/store/map-store';
import type { SidebarItemConfig } from './common';
import { icons } from '@/utils/icons';

export function studentSidebarConfig(
  reviewNounCapitalisedAndPluralised: string
): SidebarItemConfig<StudentSidebarConfig>[] {
  const targetsPage: (earlyCareers: boolean) => StudentSidebarConfig = earlyCareers => ({
    icon: icons.target,
    title: 'Targets',
    pageNameSuffix: 'TargetsListPage',
    show: (_, institution) => institution.config.early_careers === earlyCareers,
    childPageNameSuffixes: ['TargetCreatePage', 'TargetPage'],
  });
  const reviewsPage: (earlyCareers: boolean) => StudentSidebarConfig = earlyCareers => ({
    icon: icons.reviewPoint,
    title: reviewNounCapitalisedAndPluralised,
    pageNameSuffix: 'ReviewsListPage',
    childPageNameSuffixes: ['ReviewPage', 'ReviewStandardPage', 'ReviewOverallPage', 'ReviewPart2Page'],
    legacyChildPageNameSuffixes: [
      'LegacyReviewPage',
      'LegacyReviewStandardPage',
      'LegacyReviewOverallReviewPage',
      'LegacyReviewRequirementsPage',
    ],
    studentBadge: user => (user.student.unsubmitted_review_count > 0 ? user.student.unsubmitted_review_count : null),
    show: (_, institution) => institution.config.show_reviews && institution.config.early_careers === earlyCareers,
  });

  return [
    {
      icon: 'home',
      title: 'Home',
      pageNameSuffix: 'HomePage',
      hideForStaff: true,
      childPageNameSuffixes: ['AnnouncementsListPage', 'AnnouncementPage'],
    },
    {
      icon: icons.home,
      title: 'Details',
      pageNameSuffix: 'StudentDetailsPage',
      staffAnyPermissions: ['Admin', 'student.details.view'],
      hideForStudents: () => true,
    },
    {
      icon: icons.manageInduction,
      title: 'Manage Induction',
      pageNameSuffix: 'EctProgressPage',
      show: (_1, _2, _3, student) => !!student.ect,
      staffAnyPermissions: ['Admin'],
      hideForStudents: () => true,
    },
    {
      icon: icons.files,
      title: 'Files',
      pageNameSuffix: 'FilesListPage',
      tutorPageNameOverride: 'TutorStudentFilesListPage',
      show: (cohort, institution) => !institution.config.early_careers && cohort.show_student_files,
    },
    {
      icon: icons.course,
      title: 'Course',
      children: [
        {
          icon: icons.curriculum,
          title: 'Curriculum',
          pageNameSuffix: 'CurriculumPage',
          childPageNameSuffixes: ['CurriculumStatementPage', 'CurriculumTeachingStrategyPage'],
          show: (_, __, curriculumVisible) => curriculumVisible,
        },
        {
          icon: icons.trainingPlan,
          title: 'Training Plan',
          pageNameSuffix: 'CohortCoursePage',
          childPageNameSuffixes: ['CohortCourseWeekPage'],
          show: (cohort, _) => cohort.has_course,
        },
        {
          icon: icons.mentorMeeting,
          title: 'Mentor Meetings',
          pageNameSuffix: 'MentorMeetingsListPage',
          childPageNameSuffixes: [
            'MentorMeetingPage',
            'MentorMeetingCreatePage',
            'MentorMeetingTargetCreatePage',
            'MentorMeetingTargetPage',
          ],
          show: (_, institution) => institution.config.show_mentor_meetings && !institution.config.early_careers,
        },
        {
          icon: icons.courseActivity,
          title: 'Activities',
          pageNameSuffix: 'AssignmentsListPage',
          childPageNameSuffixes: ['AssignmentPage', 'AssignmentLatestVersionPage'],
          show: (cohort, _) => cohort.show_assignments,
        },
        {
          icon: icons.calendar,
          title: 'Calendar',
          pageNameSuffix: 'CalendarPage',
          show: (_, institution) => institution.config.show_calendar_for_students,
          staffAnyPermissions: ['student.calendar'],
        },
      ],
    },
    {
      icon: icons.development,
      title: 'Development',
      children: [
        targetsPage(false),
        {
          icon: icons.reflection,
          title: 'Reflections',
          pageNameSuffix: 'ReflectionsListPage',
          childPageNameSuffixes: ['ReflectionCreatePage', 'ReflectionPage'],
          show: (_, institution) => !institution.config.early_careers,
          staffAnyPermissions: ['student.reflections.view'],
        },
        {
          icon: icons.lessonObservation,
          title: 'Lesson Observations',
          pageNameSuffix: 'LessonObservationsListPage',
          childPageNameSuffixes: ['LessonObservationPage', 'LessonObservationCreatePage'],
          show: (_, institution) => institution.config.show_lesson_observations && !institution.config.early_careers,
        },
      ],
    },
    targetsPage(true),
    {
      icon: icons.review,
      title: 'Review',
      children: [
        reviewsPage(false),
        {
          icon: icons.evidence,
          title: 'Evidence',
          pageNameSuffix: 'EvidenceListPage',
          show: (_, institution) => institution.config.show_evidence && !institution.config.early_careers,
        },
        {
          icon: icons.portfolio,
          title: 'Portfolio',
          pageNameSuffix: 'PortfolioOverviewPage',
          childPageNameSuffixes: ['PortfolioStandardPage'],
          show: (_, institution) =>
            institution.config.show_evidence && institution.config.show_portfolio && !institution.config.early_careers,
        },
        {
          icon: icons.proficiencies,
          title: 'Proficiencies',
          pageNameSuffix: 'ProficienciesPage',
          show: (_, institution) => institution.config.has_proficiencies && !institution.config.early_careers,
          hideForStudents: institution => !institution.config.students_see_proficiencies,
          staffAnyPermissions: ['student.proficiencies.edit'],
        },
      ],
    },
    reviewsPage(true),
  ];
}

export interface StudentSidebarConfig {
  icon: string;
  title: string;
  pageNameSuffix: string;
  tutorPageNameOverride?: string;
  childPageNameSuffixes?: string[];
  legacyChildPageNameSuffixes?: string[];
  studentBadge?: (user: StudentUser) => number | null;
  show?: (
    cohort: Cohort | StudentCohort,
    institution: Institution,
    curriculumVisible: boolean,
    student: Student | SelectedStudent
  ) => boolean;
  // To see if it's visible for staff they will also require at least one of these permission (if supplied)
  staffAnyPermissions?: Permission[];
  // Staff and student specific overrides of `show`
  hideForStaff?: boolean;
  hideForStudents?: (institution: Institution) => boolean;
}
